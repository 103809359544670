import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';
import ScrollablePage from '../../layouts/scrollable-page';
import Slogan from '../../components/slogan';
import Arrow from '../../components/arrow';
import gsap from 'gsap';
import { useDispatch, useSelector } from 'react-redux';
import { getCaseStudies } from '../../helpers/axios';
import { setCaseStudies } from '../../state/caseStudies';
import CaseStudyShowcase from '../../components/case-study-showcase';
import Footer from '../../components/footer';
import WorkWithUs from '../../components/work-with-us';
import SEO from '../../components/seo';
import useImagePreloader from '../../hooks/useImagePreloader';
import ScrollablePageHeader from '../../components/scrollable-page-header';
import cube from '../../assets/images/shapes/topdown-renders/cube.png';
import sphere from '../../assets/images/shapes/topdown-renders/sphere.png';
import torus from '../../assets/images/shapes/topdown-renders/torus.png';
import { scrollableHeaderLandingAnimation } from '../../animations/hero';
import {animateCaseStudyPreview} from '../../animations/our-work';
const OurWork = () => {
  const scrollBarRef = useRef();
  const rootRef = useRef();
  const contentRef = useRef();
  const dispatch = useDispatch();
  const caseStudies = useSelector(
    (state) => state.caseStudies.caseStudies || []
  );

  const imagePreloader = useImagePreloader([
    cube,
    sphere,
    torus,
    ...caseStudies.map((cs) => cs.attributes.featureImage.data.attributes.url),
  ]);
  const fetchCaseStudies = async () => {
    const response = await getCaseStudies();
    dispatch(setCaseStudies(response.data));
  };

  useEffect(() => {
    if (caseStudies.length === 0) {
      fetchCaseStudies();
      return;
    }

    imagePreloader.startPreloading();
  }, [caseStudies]);

  useEffect(() => {
    if (imagePreloader.isLoading) return;

    // initiate animation sequence
    scrollableHeaderLandingAnimation(rootRef);

    // initiate scrolled content animation triggers
    animateCaseStudyPreview(contentRef, scrollBarRef);
  }, [imagePreloader.isLoading]);

  return (
    <ScrollablePage simpleBarRef={scrollBarRef}>
      <SEO
        title={'Lucky Beard | Design and Advisory | Our Work'}
      />
      <div ref={rootRef} className={`${styles.ourWorkWrapper}`}>
        <ScrollablePageHeader
          scrollablePageRef={scrollBarRef}
          subHeading={'What we do'}
          heading={'Our work'}
          description={`A couple of things that have been brought to life through our
          diverse team of specialists, who work seamlessly together to make a
          difference.`}
          images={{ shape1: sphere, shape2: cube, shape3: torus }}
        />

        <div ref={contentRef} className={`${styles.content}`}>
          {caseStudies.map((cs) => (
            <CaseStudyShowcase
              slug={cs.attributes.slug}
              key={cs.attributes.title}
              title={cs.attributes.title}
              roadmap={cs.attributes.roadmap}
              description={cs.attributes.description}
              website={cs.attributes.website}
              image={cs.attributes.featureImage.data.attributes.url}
              category={cs.attributes.case_study_category.data.attributes.title}
              weDid={cs.attributes.case_study_works.data
                .map((csw) => csw.attributes.title)
                .join(', ')}
            />
          ))}
        </div>

        <WorkWithUs bgColor='#f9f9fa' shape={torus} />

        <Footer />
      </div>
    </ScrollablePage>
  );
};

export default OurWork;
