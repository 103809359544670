// extracted by mini-css-extract-plugin
export var caseStudyPreviewWrapper = "index-module--case-study-preview-wrapper--bZ9Ir";
export var fixed = "index-module--fixed--AI64c";
export var imageBlock = "index-module--image-block--6ePwK";
export var tabletAndSmaller = "index-module--tablet-and-smaller--bI97J";
export var contentWrapper = "index-module--content-wrapper--4uLmY";
export var content = "index-module--content--doa-+";
export var imageContainer = "index-module--image-container--QMub7";
export var weDidBlock = "index-module--we-did-block--8Xj91";
export var squigglyWrapper = "index-module--squiggly-wrapper--TvITE";
export var sloganWrapper = "index-module--slogan-wrapper--8vU1E";
export var title = "index-module--title--3VKGm";
export var description = "index-module--description--Usb-q";
export var viewCaseStudy = "index-module--view-case-study--r--Rl";
export var row = "index-module--row--NGJF2";
export var column = "index-module--column---B6Iw";