import gsap, {Power4} from 'gsap';
import { isMobile } from 'react-device-detect';
const animateCaseStudyPreview = (node, scrollBarRef) => {
    if (!node || !node.current) return;
    const duration = 1;
    const easing = Power4.easeOut;
    node.current.querySelectorAll('.cspw').forEach((item) => {
        // here we define a timeline with one scrolltrigger, that triggers multiple tweens for copy and images respectively
        const tl = gsap.timeline({ scrollTrigger: {
            trigger: item,
            start: `top ${isMobile ? '70%' : '40%'}`,
            scroller: scrollBarRef.current,
            // markers:true
        }});

        const tBlock = gsap.from(item.querySelector('.ib').children, {
            duration,
            ease: easing,
            x:-10,
            stagger:0.1,
            opacity:0,

        });
        const wwdBlock = gsap.from(item.querySelector('.wdb'), {
            duration,
            ease: easing,
            x:-10,
            opacity:0
        });

        gsap.set(item.querySelector('.imgb'), {
            backgroundPositionY: 0,
        });

        const imgBlock = gsap.from(item.querySelector('.imgb'), {
            backgroundPositionY:-10,
            scale:1.05,
            duration: duration + 0.5,
            ease: easing,
            opacity:0
        });

        tl.add(tBlock, 0);
        tl.add(wwdBlock, 0.2);
        tl.add(imgBlock, 0.3);
    });
    
};

export {animateCaseStudyPreview};