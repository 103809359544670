import React from 'react';
import Arrow from '../../../assets/images/case-study-arrow.svg';
import * as styles from './index.module.scss';
const ButtonLink = ({ text }) => {
  return (
    <div className={styles.buttonLinkWrapper}>
      <span> {text} </span> <img src={Arrow} />
    </div>
  );
};
export default ButtonLink;
