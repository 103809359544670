import React, { useEffect, useState, useRef } from 'react';
import * as styles from './index.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
import chevronLeft from '../../assets/images/chevron-left.svg';
import chevronRight from '../../assets/images/chevron-right.svg';
import chevronRightDark from '../../assets/images/chevron-right-dark.svg';
const Button = ({ text, onClick, icon, iconPosition, disabled, type, customStyles }) => {

  return (
    <button
      onClick={onClick}
      className={`utility-button
      ${styles.button}
      ${(!type || type === 'primary') && styles.primary}
      ${type === 'secondary' && styles.secondary}
      ${type === 'ghost' && styles.ghost}
      ${type === 'button-input' && styles.buttonInput}
       ${disabled ? styles.disabled : ''} ${customStyles || ''} 
       `}
    >
      {icon && iconPosition === 'left' && (
        <img alt="a icon of a chevron pointing left" className={styles.iconLeft} src={chevronLeft} />
      )}
      {text}
      {icon && iconPosition === 'right' && (
        <img alt="a icon of a chevron pointing right" className={styles.iconRight} src={icon === 'chevron-right' ? chevronRight : chevronRightDark} />
      )}
    </button>
  );
};

export default Button;
