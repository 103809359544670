import axios from 'axios';

const apiCall = async (type, url, method, params, headers) => {
  const baseURL = type === 'jobvite' ? process.env.GATSBY_JOBVITE_ENDPOINT : process.env.GATSBY_CMS_ENDPOINT;
  return axios({
    method: method || 'GET',
    baseURL: baseURL,
    url: url || '/',
    params: params || {},
    headers: headers || {},
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

const getCaseStudies = async () => {
  return apiCall('cms', '/case-studies', 'GET', {
    populate: [
      'featureImage',
      'caseStudyComponents',
      'caseStudyComponents.clientLogo',
      'caseStudyComponents.backgroundImage',
      'caseStudyComponents.desktop',
      'caseStudyComponents.desktop1',
      'caseStudyComponents.desktop2',
      'caseStudyComponents.desktop3',
      'caseStudyComponents.desktop4',
      'caseStudyComponents.desktop5',
      'caseStudyComponents.desktop6',
      'caseStudyComponents.mobile',
      'caseStudyComponents.mobile1',
      'caseStudyComponents.mobile2',
      'caseStudyComponents.mobile3',
      'caseStudyComponents.mobile4',
      'caseStudyComponents.mobile5',
      'caseStudyComponents.mobile6',
      'caseStudyComponents.image',
      'caseStudyComponents.image1',
      'caseStudyComponents.image2',
      'caseStudyComponents.image3',
      'caseStudyComponents.image4',
      'case_study_category',
      'case_study_works',
      'SEO',
      'SEO.SEOImage',
      'SEO.SEOImage.media',
    ],
  });
};

const getTestimonials = () => {
  return apiCall('cms', '/testimonials', 'GET', {
    'pagination[pageSize]': 100,
    populate: '*',
  });
};


const getFAQs = () => {
  return apiCall('cms', '/faqs', 'GET', {
    'pagination[pageSize]': 50,
    populate: '*',
  });
};

const getInstagramPosts = () => {
  return apiCall('cms', '/instagram/images', 'GET', {
    'pagination[limit]': 15,
    populate: '*',
  });
};

const getAllJobs = () => {
  return apiCall('jobvite', '/v2/job', 'GET', {
    api: process.env.GATSBY_JOBVITE_API_KEY,
    sc: process.env.GATSBY_JOBVITE_SECRET,
    jobStatus: 'Open',
    availableTo: 'External'
  });
};

export { apiCall, getCaseStudies, getTestimonials, getFAQs, getInstagramPosts, getAllJobs };
