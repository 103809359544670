import { useEffect, useState, useRef } from 'react';
import useTimeout from './useTimeout';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../state/preloading';

const useImagePreloader = (images) => {
  const latestImages = useRef(images);
  const [canPreload, setCanPreload] = useState(false);
  const [isLoading, setLocalIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsLoading(true));
  },[]);

  useEffect(() => {
    latestImages.current = images;
  }, [images]);

  const timeout = useTimeout(() => {
    if (isLoading) {
      setLocalIsLoading(false);
      dispatch(setIsLoading(false));
    }
  }, 10000); // 10 seconds before a image preloading timeout is reached, then it will display page regardless

  useEffect(() => {
    timeout.startTimeout();
    if (!canPreload) return;
    cacheImages();
  }, [canPreload]);

  const cacheImages = async () => {
    await Promise.all(
      latestImages.current.map(
        (src) =>
          new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = resolve();
            img.src = src;
            img.onerror = reject();
          })
      )
    );
    setLocalIsLoading(false);
    dispatch(setIsLoading(false));
  };

  return {
    isLoading: isLoading,
    startPreloading: () => {
      setCanPreload(true);
    },
  };
};

export default useImagePreloader;
