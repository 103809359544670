import React, { useEffect, useState, useRef, forwardRef } from 'react';

const useTimeout = (callback, duration) => {
  const latestDuration = useRef();
  const latestCallback = useRef();
  const timeout = useRef();
  // if duration changes, update it
  useEffect(() => {
    latestDuration.current = duration;
  }, [duration]);

  // if callback changes, make sure to use the latest
  useEffect(() => {
    latestCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!duration) {
      return;
    }

    return () => clearTimeout(timeout.current);
  }, []);

  const createTimeout = () => {
    timeout.current = setTimeout(() => {
      latestCallback.current();
    }, duration);
  };

  return {
    startTimeout: () => {
      createTimeout();
    },
  };
};

export default useTimeout;
