import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';
import Slogan from '../slogan';
import Squiggly from '../squiggly';
import ButtonLink from '../button/button-link';
import { isMobile, isTablet } from 'react-device-detect';
import WipeLink from '../nav-transitions/wipe-link';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const CaseStudyShowcase = ({
  title,
  slug,
  description,
  weDid,
  category,
  roadmap,
  website,
  fixed,
  image,
}) => {
  const breakpoints = useBreakpoint();
  return (
    <div
      className={`cspw ${styles.caseStudyPreviewWrapper} ${
        fixed && styles.fixed
      } ${(isMobile || isTablet) && styles.tabletAndSmaller}`}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <div className={` ib ${styles.infoBlock}`}>
            <h2 className={`title-S ${styles.title}`}> {title} </h2>
            <p className={`body-S ${styles.description}`}>{description} </p>
            <WipeLink to={`/our-work/${slug}`}>
              <ButtonLink text='View Case Study' />
            </WipeLink>
          </div>

          <div className={`wdb ${styles.weDidBlock}`}>
            <div className={styles.row}>
              <div className={styles.column}>
                <h4 className={'utility-overline-heavy'}>WE DID</h4>
                <p className={'body-S'}>{weDid}</p>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.column}>
                <h4 className={'utility-overline-heavy'}>CATEGORY</h4>
                <p className={'body-S'}>{category}</p>
              </div>
              <div className={styles.column}>
                <h4 className={'utility-overline-heavy'}>TIMEFRAME</h4>
                <p className={'body-S'}>{roadmap}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WipeLink className={`${styles.imageBlock}`} to={`/our-work/${slug}`}>
        <div
          className={`imgb ${styles.imageBlock}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className={styles.imageContainer}>
            {!breakpoints.md && (
              <div className={styles.squigglyWrapper}>
                <Squiggly />
              </div>
            )}
            {!breakpoints.md && (
              <div className={styles.sloganWrapper}>
                <Slogan />
              </div>
            )}
          </div>
        </div>
      </WipeLink>
    </div>
  );
};

export default CaseStudyShowcase;
